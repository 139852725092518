@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

/* comman css start */

:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}

iframe[style*="position: fixed;"] {
  display: none;
}

.m-0 {
  margin: 0 !important;
}

body,
html {
  overflow-x: hidden;
}

body {
  background: #f4f5f7;
  min-height: 100vh;
  height: auto;
  font-family: "Roboto", sans-serif !important;
}

textarea {
  resize: vertical;
}

.ml-auto {
  margin-left: auto;
}

.w-0 {
  display: none !important;
}

.mt-15 {
  margin-top: 15px;
}

textarea,
button {
  font-family: "Roboto", sans-serif !important;
}

.Layout {
  display: flex;
}

#sidebar_toggel {
  display: none;
}
.text-overflow-address {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 400px;
}

.main_content {
  max-width: calc(100% - 280px);
  width: 100%;
  transition: all 0.5s ease-in-out;
  margin-left: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  z-index: 99;
}

.chat_btn {
  background: none;
  border: none;
  text-decoration-line: underline;
  cursor: pointer;
}

#sidebar_toggel:checked ~ .main_content {
  max-width: calc(100% - 90px);
}

.text_right {
  text-align: right !important;
}

.comman_btn {
  font-size: 14px;
  padding: 6px 28px;
  font-weight: 600;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
}

.small_comman_btn {
  font-size: 12px;
  padding: 4px 20px;
  font-weight: 600;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
}

.prop_verify_btn {
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 33px;
}

.reject_verify_un_verify_btn {
  margin-right: 10px;
}

.featured_btn {
  padding: 2px 10px;
}

.featured_btn:hover::after {
  content: " Add to Featured";
}
.unfeatured_btn {
  padding: 2px 10px;
}

.unfeatured_btn:hover::after {
  content: " Remove from Featured";
}

.featured_btn_width {
  width: 30%;
}

.save_featured_cont {
  display: block;
}

.save_featured {
  margin-top: 1rem;
}

.legal_doc_button {
  padding: 20px 0 0;
}

.basic-multi-select {
  z-index: 9999;
}

/* .comman_btn:last-child {
  margin-left: 2rem;
} */

.select__single-value--is-disabled {
  color: #ccc !important;
}

.plan_list_id {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.create_from_bottom_btn {
  gap: 16px;
  display: flex;
}

.margin_btn_cr {
  margin-left: 10px;
}

.feedback_status-btn {
  justify-content: center;
}

.comman_btn:hover {
  background-color: #9d182f;
  border-color: #9d182f;
}

.comman_btn:disabled {
  background: #bf4d60;
  border: 1px solid #bf4d60;
  color: #fff;
  cursor: not-allowed;
}

.project_submit_bottom_btn {
  margin-top: 16px;
}

.project_submit_bottom_btn.center_back_btn {
  padding: 20px 0 0;
  max-width: 1100px;
  margin: 0 auto;
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.col_span_2 {
  grid-column: span 2 / span 2;
}

.border-bottom-span {
  border-bottom: 1px dashed #000;
}
.border-bottom-span-minor {
  border-bottom: 0.5px dashed #d8d8d8;
}

.add_more_input_btn_container {
  display: flex;
  justify-content: end;
}

.error_modal_msg {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  display: block;
  position: fixed;
  right: 40px;
  top: 40px;
  z-index: 999;
}

.col_lg_3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.col_lg_4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.d_grid {
  display: grid;
}

.gap_15 {
  gap: 15px;
}

.footer_section {
  margin-top: auto;
  background: #ffffff;
  padding: 15px 40px;
  display: flex;
  align-items: center;
  box-shadow: 0px -15px 30px 0px rgb(0 0 0 / 2%);
  border-top: 2px solid #c1203b1f;
}

/* comman css end */

/* sidebar css start */

.sidebar_container {
  box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 2%);
  min-height: 100vh;
  height: auto;
  max-width: 280px;
  width: 100%;
  background: #fff;
  padding: 15px 20px;
  transition: all 0.5s ease-in-out;
  border-right: 1px solid #c1203b1f;
  position: fixed;
  /* z-index: 999; */
}

.sidebar_container .sidebar_logo {
  display: block;
}

.sidebar_container .sidebar_logo img {
  max-width: 160px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.sidebar_container .sidebar_nav {
  list-style: none;
  margin: 30px -20px -15px;
  max-height: calc(100vh - 81px);
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar_container .sidebar_nav::-webkit-scrollbar {
  width: 0;
}

/* Track */
.sidebar_container .sidebar_nav::-webkit-scrollbar-track {
  background: #c11f3b1a;
}

/* Handle */
.sidebar_container .sidebar_nav::-webkit-scrollbar-thumb {
  background: #c11f3b;
  border-radius: 50px;
}

/* Handle on hover */
.sidebar_container .sidebar_nav::-webkit-scrollbar-thumb:hover {
  background: #c11f3b;
  border-radius: 50px;
}

.sidebar_container .sidebar_nav {
  list-style: none;
  margin: 30px -20px -15px;
  max-height: calc(100vh - 81px);
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link {
  padding: 12px 25px;
  font-size: 14px;
  font-weight: 500;
  color: #3f4358;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link::after {
  content: "";
  position: absolute;
  right: -1px;
  top: 50%;
  width: 0;
  height: 45px;
  border-radius: 60px 0 0 60px;
  background: #c11f3b;
  transition: all 0.5s ease-in-out;
  transform: translateY(-50%);
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link svg {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  color: #c22540;
  margin-right: 16px;
  transition: all 0.5s ease-in-out;
  background: #c11f3b1a;
  border-radius: 5px;
  padding: 7px;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active {
  color: #c11f3b;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover svg,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active svg {
  background: #c11f3b;
  color: #ffffff;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover::after,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active::after {
  width: 6px;
}

#sidebar_toggel:checked ~ .sidebar_container {
  max-width: 90px;
}

#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link
  span,
#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link::before {
  display: none;
}

#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link
  svg {
  margin: 0 auto;
}

.sidebar_toggel_btn {
  cursor: pointer;
  color: #c11f3b;
  min-width: 36px;
  max-width: 36px;
  height: 34px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar_toggel_btn:hover {
  background: #c11f3b1a;
}

.sidebar_toggel_btn svg {
  width: 20px;
}

.sidebar_container .sidebar_logo .small_sidebar_logo,
#sidebar_toggel:checked ~ .sidebar_container .sidebar_logo .full_sidebar_logo {
  display: none;
}

#sidebar_toggel:checked ~ .sidebar_container .sidebar_logo .small_sidebar_logo {
  display: block;
}

.sidebar_container .sidebar_logo .small_sidebar_logo {
  width: 35px;
  height: 35px;
  margin: 0 auto;
}

/* #sidebar_toggel:checked  */

/* sidebar css end */

/* top header css end */

.top_header_section {
  background: #ffffff;
  padding: 15px 40px;
  display: flex;
  align-items: center;
  position: fixed;
  left: auto;
  top: 0;
  width: calc(100% - 280px);
  z-index: 99999;
  transition: all 0.5s ease-in-out;
}

#sidebar_toggel:checked ~ .main_content .top_header_section {
  width: calc(100% - 89px);
  left: auto;
}

#sidebar_toggel:checked
  ~ .main_content
  .project_edit_content
  .project_edit_navbar {
  max-width: calc(100% - 89px);
  left: auto;
}

.top_header_section .top_navbar_nav {
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.top_header_section .top_navbar_nav .top_navbar_item .top_navbar_logout_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
}

.top_header_section
  .top_navbar_nav
  .top_navbar_item
  .top_navbar_logout_btn
  svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.top_header_section
  .top_navbar_nav
  .top_navbar_item
  .top_navbar_logout_btn:hover {
  color: #ffffff;
  background: #c11f3b;
}

.top_header_section .page_name_heading {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  line-height: 21.6px;
  text-transform: capitalize;
}

/* top header css end */

/* login css start */

.login_section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  padding: 0 15px;
  background-image: url("../images/loginBgimg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.login_section::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(33, 64, 93, 75%);
}

.login_section .login_content {
  max-width: 451px;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  z-index: 999;
}

.login_section .login_content .login_top_content {
  padding: 24px 24px 40px;
  background-image: url(../images/login-cover-image.jpg);
  background-size: cover;
  border-radius: 4px 4px 0 0;
}

.login_section .login_content .login_top_content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #c11f3b;
}

.login_section .login_content .login_top_content p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  color: #c11f3b;
}

.login_section .login_content .login_input_logo_content {
  padding: 0 20px 20px;
}

.login_section .login_content .login_input_logo_content .login_logo {
  width: 72px;
  height: 72px;
  background: #ffdad2;
  border-radius: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -26px;
}

.login_section .login_content .login_input_logo_content .login_logo svg {
  width: 42px;
  height: 42px;
}

.login_section .login_content .login_input_logo_content .login_input_content {
  padding: 32px 8px 8px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row {
  margin-bottom: 16px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  input {
  width: 36px !important;
  height: 36px !important;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  > div {
  margin: 0 -10px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  > div
  > div {
  margin: 0 10px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  color: #495057;
  width: 100%;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  input {
  display: block;
  width: 100%;
  padding: 7.5px 12px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  outline: 0;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_btn {
  width: 100%;
  color: #fff;
  background-color: #c11f3b;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #c11f3b;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.sendotp_for_delete {
  background-color: #fff;
  font-size: 14px;
  color: #717171;
  display: block;
  width: 50%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_btn:hover {
  background-color: #9d182f;
  border-color: #9d182f;
}

/* login css end */

/* footer css start */

.footer_section p {
  font-size: 14px;
  font-weight: 500;
  color: #999;
}

.footer_section .footer_version_text {
  margin-left: auto;
}

/* footer css end */

/* project edit page css start */

.project_edit_content .project_edit_navbar {
  display: flex;
  align-items: center;
  list-style: none;
  background: #ffffff;
  padding: 10px 20px;
  box-shadow: 6px 6px 8px 2px #0000000d;
  border-top: 1px solid #eeeeee;
  overflow-x: auto;
  position: fixed;
  top: 64px;
  max-width: calc(100% - 280px);
  left: auto;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 999999;
}

.project_edit_content .project_edit_navbar .project_edit_items {
  padding: 0 5px;
}

.project_edit_content .project_edit_navbar .project_edit_items:first-child {
  padding-left: 0;
}

.project_edit_content .project_edit_navbar .project_edit_items:last-child {
  padding-right: 0;
}

.project_edit_content
  .project_edit_navbar
  .project_edit_items
  .project_edit_link {
  padding: 7px 15px;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  width: max-content;
  margin: 0;
}

.project_edit_content
  .project_edit_navbar
  .project_edit_items
  .project_edit_link:hover,
.project_edit_content
  .project_edit_navbar
  .project_edit_items.open
  .project_edit_link {
  border-color: #c11f3b;
  color: #c11f3b;
}

.project_edit_main_content {
  padding: 20px;
}

.main_content .project_edit_content .project_edit_page_body {
  padding-top: 73px;
}

.project_edit_main_content .project_edit_feature_media_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
}

.project_edit_main_content .project_edit_detail_column_content {
  background: #ffffff;
  box-shadow: 0 0 8px 2px #0000000d;
  padding: 15px;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
}

.csv_file_select {
  background: #ffffff;
  box-shadow: 0 0 8px 2px #0000000d;
  padding: 15px;
  border-radius: 4px;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file {
  border: 1px solid #000000;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  input {
  display: none;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c11f3b35;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label
  span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label
  svg {
  width: 15px;
  height: 15px;
  color: #c11f3b;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file:hover
  label {
  transform: scale(1);
  opacity: 1;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_edit_main_content .project_edit_detail_column_content h2 {
  font-size: 22px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #2c323f;
}

.project_edit_main_content .project_edit_feature_input {
  margin-top: 16px;
}

.project_edit_main_content .project_edit_feature_input input[type="file"] {
  display: none;
}

.project_edit_main_content .project_edit_feature_input label {
  /* display: block; */
  width: 100%;
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
}

.project_edit_main_content .project_edit_feature_input input,
.project_edit_main_content .project_edit_feature_input textarea {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.project_edit_feature_add_remove_btn_row {
  display: flex;
  align-items: center;
  margin-top: 16px !important;
  justify-content: space-between;
}

.project_edit_feature_add_remove_btn_row .project_edit_feature_add_remove_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  cursor: pointer;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn.project_edit_feature_add_btn {
  margin-left: auto;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn
  svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn:hover {
  color: #ffffff;
  background: #c11f3b;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_input_file
  input {
  display: none;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_input_file
  label {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 1px solid #c11f3b;
  cursor: pointer;
  width: 100%;
  max-width: fit-content;
  margin-left: auto;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_input_file
  label
  svg {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_image_section {
  border: 1px dashed #000;
  padding: 15px;
  border-radius: 4px;
  min-height: 207px;
  height: auto;
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_image_section
  .project_edit_media_image_column {
  /* max-width: 175px; */
  max-height: 175px;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_image_section
  .project_edit_media_image_column
  img {
  width: 100%;
  height: 175px;
  object-fit: contain;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_image_section
  .project_edit_media_image_column
  .image_gallery_remove_btn {
  width: 18px;
  height: 18px;
  border: 1px solid #c11f3b;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  background: #ffffff;
  color: #c11f3b;
  cursor: pointer;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_image_section
  .project_edit_media_image_column
  .image_gallery_remove_btn
  span {
  width: 10px;
  height: 2px;
  background: #c11f3b;
  border-radius: 50px;
  display: block;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_image_section
  .project_edit_media_image_column
  .image_gallery_remove_btn:hover {
  background: #c11f3b;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_media_image_section
  .project_edit_media_image_column
  .image_gallery_remove_btn:hover
  span {
  background: #fff;
}

.amenieties_file_select_content .select_amenieties_file_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 1px solid #c11f3b;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
}

.amenieties_file_select_content .select_amenieties_file_btn svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.amenieties_file_select_content input {
  display: none;
}

.profile_edit_iframe {
  width: 100%;
  height: 300px;
  margin-top: 16px;
}

.company_pdf {
  width: 90%;
  height: 150px;
}

.project_edit_main_content .tower_name_first_input {
  margin-top: 0;
}

.creat_edit_project_btn_row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.button_margin {
  margin-top: 22px;
}

.customer_property-header {
  align-items: flex-start;
  justify-content: space-between;
}

/* project edit page css end */

/* user list page css start */

.user_data_search .user_data_search_input,
.basic-multi-select .select__control {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.basic-multi-select .select__control .select__value-container,
.basic-multi-select
  .select__control
  .select__value-container
  .select__single-value,
.basic-multi-select
  .select__control
  .select__value-container
  .select__input-container,
.basic-multi-select
  .select__control
  .select__value-container
  .select__input-container
  input {
  padding: 0;
  margin: 0;
  color: #717171;
}

.basic-multi-select .select__control .select__indicators {
  margin: -6px -6px -6px 0;
}

.basic-multi-select .select__control .select__indicators .select__indicator {
  padding: 6px;
}

.basic-multi-select .select__control {
  display: flex;
  align-items: center;
  box-shadow: none;
  min-height: auto;
}

.user_data_search_select_box_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
  margin: -8px -8px 12px;
}

.user_data_search_select_box_row .user_data_search {
  position: relative;
  max-width: 230px;
  width: 100%;
  margin: 8px !important;
}

.user_data_search_select_box_row .user_data_search .user_data_search_input {
  padding-right: 35px;
}

.user_data_search_select_box_row .user_data_search .user_data_search_btn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  height: 100%;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user_data_search_select_box_row .user_data_search .user_data_search_btn svg {
  height: 17px;
  width: 17px;
  color: #cccccc;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn:hover
  svg {
  color: #000;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 17px;
  background: #cccccc;
}

.user_data_search_select_box_row .user_data_select {
  max-width: 220px;
  width: 100%;
  /* New line added */
  margin: 8px;
}

.user_list-totalnumber {
  margin-right: 16px;
  background: #c11f3b;
  padding: 5px;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 65px;
}

.lead_status-options {
  margin-left: 1rem;
}

/* user list page css end */

/* create page css start */

.create_from_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: -3px 6px 13px 0.5px #0000000d;
  padding: 20px 25px;
  max-width: 1100px;
  margin: 0 auto;
}

.tower_dropdown {
  z-index: 99;
}
.tower_dropdown_1 {
  z-index: 9999;
}
.plan_dropdown_1 {
  z-index: 999;
}

.user_dropdown {
  z-index: 9999;
  position: relative;
}
.create_from_input_content_btn {
  position: absolute;
  right: 0;
  padding: 5px 16px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
}
.create_from_input_content_btn:hover {
  color: #ffffff;
  background: #c11f3b;
}
.create_from_input_content_btn svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.plan_dropdown {
  z-index: 9;
}
.property_label {
  flex-grow: 1;
}

.create_from_input_content .create_from_label {
  display: inline-block;
  /* width: 100%; */
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
  width: auto;
}
.date_picker_container .create_from_label {
  display: inline-block;
  /* width: 100%; */
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
  width: auto;
}
.create_from_input_content .long-label-input {
  width: 100%;
}

.btn-container-create-form {
  display: flex;
  justify-content: flex-start;
  align-items: end;
  margin-bottom: 0px;
}

.btn-container-create-form .create-multi-input-btn {
  font-size: 12px;
  padding: 7px 20px;
  font-weight: 600;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 5px;
}
.btn-container-create-form .create-multi-input-btn:disabled {
  background: #bf4d60;
  border: 1px solid #bf4d60;
  color: #fff;
  cursor: not-allowed;
}

.create_from_input_content .create_from_input {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}
.create_from_input_content input:disabled {
  background-color: #fff;
  color: #afafaf;
}

.create_from_input_content .create_from_input::placeholder,
.css-14el2xx-placeholder,
.project_edit_main_content .project_edit_feature_input input::placeholder,
.project_edit_main_content .project_edit_feature_input textarea::placeholder {
  color: #00000059 !important;
  letter-spacing: 2px;
}

.create_from_row .create_from_input_content .create_from_radio_checkbox {
  display: none;
}

.create_from_row .create_from_input_content .create_from_radio_label,
.create_from_radio_label {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  color: #242934;
  padding-left: 25px;
  cursor: pointer;
}

.create_from_row .create_from_input_content .create_from_radio_label::before,
.create_from_radio_label::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  border: 1px solid #c11f3b;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.create_from_row .create_from_input_content .create_from_radio_label::after,
.create_from_radio_label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 3px;
  border-radius: 50%;
  background-color: #c11f3b;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
    -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.create_from_row
  .create_from_input_content
  .create_from_radio_label.checked::after,
.create_from_radio_label.checked::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content {
  margin-bottom: 5px;
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content:last-child {
  margin-bottom: 0;
}

.create_from_row .create_from_input_content input[type="file"] {
  display: none;
}

.create_from_row .create_from_input_content .create_from_file_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 1px solid #c11f3b;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
}

.create_from_row .create_from_input_content .create_from_file_btn svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.create_from_row .create_from_input_content .create_from_input_file {
  /* border: 1px solid #000000; */
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  border-color: #e6edef;
  border: 1px solid #ced4da;
}

.create_from_row .create_from_input_content .create_from_input_file label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c11f3b35;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

.create_from_row
  .create_from_input_content
  .create_from_input_file:hover
  label {
  transform: scale(1);
  opacity: 1;
}

.create_from_row .create_from_input_content .create_from_input_file label span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.create_from_row
  .create_from_input_content
  .create_from_input_file
  label
  span
  svg {
  width: 15px;
  height: 15px;
  color: #c11f3b;
}

.create_from_row .create_from_input_content .create_from_input_file img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image_remove_btn {
  background: #c11f3b;
  color: #fff;
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 7px;
  top: 7px;
  box-shadow: -2px 3px 8px 2px #00000035;
  cursor: pointer;
  z-index: 9900;
}

.image_remove_btn svg {
  width: 15px;
  height: 15px;
}

.create_from_row .create_from_input_content .create_from_input_file img ~ label,
.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  img
  ~ label,
.create_from_input_file_specification img ~ label {
  display: none;
}

.create_from_row .create_from_input_content .create_from_plan_document_input {
  margin-top: 16px;
}

.create_from_row .create_from_input_content .create_from_extra_added_content {
  margin-bottom: 16px;
}

.create_from_row
  .create_from_input_content
  .create_from_extra_added_content:last-child {
  margin-bottom: 0;
}

.customer_properties_number_input .create_from_input {
  max-width: 50%;
}

.customer_properties_number_input .create_from_bottom_btn {
  margin-top: 16px;
}

.customer_properties_number_extra_added_content {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
}

.project_edit_detail_column_content .create_from_input_content {
  margin-bottom: 15px;
}

.project_edit_detail_column_content .create_from_input_content:last-child {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.project_detail-name {
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: 14px;
  padding: 2px 7px;
  font-weight: 500;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  vertical-align: middle;
  border-radius: 4px;
}

/* create page css end */

/* date picker css start */
.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block !important;
  margin: 0 2px !important;
}
/* date picker css end */

/* error filed css start */

/* .error_filed .create_from_label, */
.error_filed .create_from_input::placeholder,
.error_filed .select__placeholder {
  color: #f00;
}

.error_filed .create_from_input,
.error_filed .basic-multi-select .select__control,
.error_filed
  .basic-multi-select
  .select__control
  .select__indicators
  .select__indicator,
.error_filed .create_from_input_file {
  color: #f00;
  border-color: #f00;
}

.error_filed .css-1okebmr-indicatorSeparator {
  background-color: #f00;
}

.error_filed .error_filed_text,
.err_text {
  color: #f00;
  /* padding: 0.35rem 0.5rem; */
  font-size: 13px;
  display: inline-block;
  margin-top: 5px;
}

.img_error {
  color: #f00;
  font-size: 13px;
  display: "flex" !important;
  flex-direction: "column" !important;
}

.error_text {
  color: #f00;
  font-size: 15px;
  display: inline-block;
}

.mobile-icon {
  padding: 6px 10px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  background-color: #c11f3b1a;
  border-radius: 4px;
}

/* error filed css end */

/* project list card css start */

.project_list_card {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 24px;
}

.project_list_card .project_list_card_img {
  width: 100%;
  box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.3),
    0px 30px 70px -40px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  min-height: 240px;
  max-height: 241px;
  object-fit: cover;
}

.project_list_card .project_list_card_body {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 275px);
}

.project_list_card .project_list_card_body .project_list_card_detaile {
  margin-bottom: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.project_list_card .project_list_card_body h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #c11f3b;
  margin-bottom: 9px;
}

.project_list_card .project_list_card_body p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006;
  font-weight: 400;
}

.project_list_card .project_list_card_body .project_list_card_bottom_btn_row {
  border-top: 2px solid #fbf1f3;
  margin-top: 14px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  margin-top: auto;
}

.project_edit_link {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn,
.project_list_card_view_edit_btn {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c11f3b;
  text-decoration: none;
  max-width: 165px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn
  svg,
.project_list_card_view_edit_btn svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:hover
  svg,
.project_list_card_view_edit_btn:hover svg {
  background: #c11f3b;
  color: #ffffff;
}

.project_list_card_view_edit_btn.link_yellow svg {
  background: #ffb8001a;
  color: #ffb800;
}

.project_list_card_view_edit_btn.link_yellow:hover svg {
  background: #ffb800;
  color: #ffffff;
}

.link_yellow {
  color: #ffb800;
}

.link_red {
  color: #c11f3b;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:last-child {
  margin: 0 auto;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn
  svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:hover
  svg {
  background: #c11f3b;
  color: #ffffff;
}

/* project list card css end */

/* lead page css start */

.referrals_label_row {
  display: flex;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  gap: 15px;
}

.referrals_label_row .referrals_label_item,
.referrals_label_row .color_label_item {
  padding-left: 18px;
  position: relative;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.007em;
  color: #240006bf;
}

.referrals_label_row .referrals_label_item::before,
.referrals_label_row .color_label_item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #c11f3b;
  border-radius: 50px;
}

.referrals_label_row .referrals_label_item.submitted::before,
.lead_list_column .lead_list_label_time_text_row.submitted::before {
  background: #79dee8 !important;
}

.referrals_label_row .referrals_label_item.confirmed::before,
.lead_list_column .lead_list_label_time_text_row.confirmed::before {
  background: #824de1 !important;
}

.referrals_label_row .referrals_label_item.visited::before,
.lead_list_column .lead_list_label_time_text_row.visited::before {
  background: #e3c15f !important;
}

.referrals_label_row .referrals_label_item.converted::before,
.lead_list_column .lead_list_label_time_text_row.converted::before {
  background: #c1e865 !important;
}

.referrals_label_row .pending::before,
.lead_list_column .lead_list_label_time_text_row.pending::before {
  background: #e10000 !important;
}

.referrals_label_row .resolved::before,
.lead_list_column .lead_list_label_time_text_row.resolved::before {
  background: #ffb800 !important;
}

.referrals_label_row .closed::before,
.lead_list_column .lead_list_label_time_text_row.closed::before {
  background: #1dce00 !important;
}

.lead_list_row {
  margin: 16px;
}

.lead_list_column {
  padding: 18px;
  background: #ffffff;
  border-radius: 5px;
}

.lead_list_column .lead_list_label_time_text_row {
  display: flex;
  align-items: center;
  padding: 5px 0;
  position: relative;
  margin-bottom: 12px;
  padding-left: 13px;
}

.lead_list_column .lead_list_label_time_text_row .lead_list_label_text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006bf;
  text-transform: capitalize;
}

.lead_list_column .lead_list_label_time_text_row::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 100%;
  background: #c11f3b;
  border-radius: 10px;
}

.lead_list_column .lead_list_label_time_text_row .lead_list_time_text {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.007em;
  color: #240006bf;
  margin-left: auto;
}

.lead_list_column .lead_list_name_other_detail .lead_list_proparty_name {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006bf;
  font-weight: 400;
  margin-bottom: 12px;
}

.lead_list_column .lead_list_name_other_detail .lead_list_user_name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.007em;
  text-transform: uppercase;
  color: #240006;
  margin-bottom: 8px;
}

.lead_list_column .lead_list_name_other_detail .lead_list_user_contact_text {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.007em;
  color: #240006bf;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn_contact_text {
  display: flex;
  align-items: center;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn {
  margin-left: auto;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  transition: all 0.5s ease-in-out;
  background: #c11f3b1a;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn:hover {
  background: #c11f3b;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn.active
  svg {
  transform: rotate(0);
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn svg {
  transform: rotate(180deg);
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn
  svg
  path {
  transition: all 0.5s ease-in-out;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn:hover
  svg
  path {
  stroke: #ffffff;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body {
  padding-top: 12px;
  margin-top: 8px;
  border-top: 1px solid #24000614;
  display: none;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.007em;
  color: #24000680;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body.show {
  display: block;
}

.lead_project {
  z-index: 99;
}

.lead_referred {
  z-index: 9;
}

.lead_table-status {
  padding: 7px 12px;
  background-color: rgba(49, 49, 49, 0.1);
}

.lead_confirmed {
  color: rgb(155, 155, 5) !important;
}
.lead_submitted {
  color: rgb(5, 5, 146) !important;
}
.lead_rejected {
  color: #c11f3b !important;
}
.lead_visited {
  color: rgb(2, 78, 2) !important;
}
.lead_converted {
  color: rgb(3, 128, 128) !important;
}

/* lead page css end */

/* media query css end */

.companydetail_image {
  display: flex;
  width: 50%;
}

.edit_view_icon {
  display: flex;
  align-items: space-around;
}

/* .back_btn {
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 3px 10px;
} */

.comman_btn_container.center_back_btn {
  padding: 20px 20px 0;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.comman_btn_container {
  padding: 20px 20px 0;
}

.remove_pdf_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  cursor: pointer;
  margin-top: 10px;
  margin-left: auto;
}

.remove_pdf_btn:hover {
  color: #ffffff;
  background: #c11f3b;
}

.remove_pdf_btn svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.create_from_input_file_specification {
  border: 1px solid #000000;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  color: #f00;
  /* margin-top: 1rem; */
}

.create_from_input_file_specification label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c11f3b35;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.create_from_input_file_specification:hover label {
  transform: scale(1);
  opacity: 1;
}

.create_from_input_file_specification label span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.create_from_input_file_specification label span svg {
  width: 15px;
  height: 15px;
  color: #c11f3b;
}

.create_from_input_file_specification img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.specification_image {
  display: flex;
}

.lead_date {
  display: flex;
}

.lead_date > * {
  padding: 0.5rem 0.1rem;
  color: grey;
  margin: 0 0 0.5rem 0;
  border-radius: 0.2rem;
}

.lead-col_item {
  width: 30%;
  max-width: 50rem;
}

.plan_list-image {
  width: 100%;
}

.text-center {
  text-align: center;
}

.tech_font {
  font-size: 1.25rem !important;
}

.tech_docs {
  display: flex;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.table {
  margin-top: 3rem;
}

.table-head {
  background-color: #ccc;
}

.table-heading {
  padding: 0.35rem 0;
}

.table-body {
  padding: 0.5rem 0;
}

.table-body-row {
  background-color: #eee;
}

.table-body-row:hover {
  background-color: #ddd;
}

.table-body-link {
  text-decoration: none;
}

/* about page css start */

.about_page_section {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  max-width: 1100px;
  margin: 0 auto;
}

.about_page_edit_create_btn {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}

.about_page_section .about_page_slider {
  padding-bottom: 50px;
  border-bottom: 2px solid #fbf1f3;
  margin: 38px auto 20px;
}

/* .about_page_section .about_page_slider .slick-slider {
  box-shadow: 0px 20px 40px -20px rgb(0 0 0 / 30%),
    0px 30px 70px -40px rgb(0 0 0 / 60%);
} */

.about_page_section .about_page_slider .slick-slider,
.about_page_section .about_page_slider .slick-slider .slick-slide,
.about_page_section .about_page_slider .slick-slider .slick-slide img {
  max-height: 350px;
  min-height: 350px;
  max-width: 765px;
  margin: 0 auto;
}

.about_page_section .about_page_slider .slick-slider .slick-slide img {
  width: 100%;
  object-fit: cover;
}

.about_page_section .about_page_slider .slick-slider .slick-prev,
.about_page_section .about_page_slider .slick-slider .slick-next {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background: #c11f3b1a;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  top: -25px;
}

.about_page_section .about_page_slider .slick-slider .slick-prev:hover,
.about_page_section .about_page_slider .slick-slider .slick-next:hover {
  opacity: 1;
  background: #c11f3b;
}

.about_page_section .about_page_slider .slick-slider .slick-prev::before {
  content: "";
  border: solid #c11f3b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: translate(-50%, -50%) rotate(135deg);
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  left: 58%;
  top: 50%;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.about_page_section .about_page_slider .slick-slider .slick-next::before {
  content: "";
  border: solid #c11f3b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  left: 44%;
  top: 50%;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.about_page_section .about_page_slider .slick-slider .slick-prev:hover::before,
.about_page_section .about_page_slider .slick-slider .slick-next:hover::before {
  border-color: #ffffff;
}

.about_page_section .about_page_slider .slick-slider .slick-prev {
  left: auto;
  right: 30px;
}

.about_page_section .about_page_slider .slick-slider .slick-list {
  border-radius: 10px;
}

.about_page_section .about_page_slider .slick-slider .slick-next {
  right: 0;
}

.about_page_section .about_page_slider .slick-slider .slick-slide {
  cursor: grab;
}

.about_page_section .about_page_slider .slick-slider .slick-slide:active {
  cursor: grabbing;
}

.about_page_section .about_detaile_text_content {
  margin-bottom: 20px;
}

.about_page_section .about_detaile_text_content:last-child {
  margin-bottom: 0;
}

.about_page_section .about_detaile_text_content h2,
.about_page_section .about_detaile_text_content h1 {
  font-size: 20px;
  color: #c11f3b;
  margin-bottom: 4px;
}

.about_page_section .about_detaile_text_content h1 {
  font-size: 30px;
}

.about_page_section .about_detaile_text_content p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}

.about_page_section .about_detaile_text_content a {
  color: #c11f3b;
}

.follow_me-link {
  text-decoration: none;
  border-bottom: 1px solid #c11f3b;
  color: #c11f3b;
  font-size: 13px;
}

.about_detaile_row {
  gap: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}
.spinner {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company_video_preview {
  width: 100%;
  height: 100%;
}

.submit-company-btn {
  margin-top: 1.5rem;
}

/* about page css end */

.featured_project_heading {
  color: #c11f3b;
  margin: 1rem 0;
}

.mobile_configuration_heading {
  color: #c11f3b;
  /* display: inline-block;
  padding: 0.5rem;
  margin-top: 1rem;
  background: #e7c3c985;
  border-radius: 0.2rem; */
}

/* data table css start */

.custom_data_table_content .custom_data_table {
  width: 100%;
  border-spacing: 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: #00000000 0px 0px 0px 0px, #00000000 0px 0px 0px 0px,
    #0000001a 0px 1px 3px 0px, #0000000f 0px 1px 2px 0px;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_head
  .custom_data_table_heading {
  background: #c11f3b;
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  text-transform: uppercase;
}

.user_delete_btn {
  width: 50% !important;
}

.modal-title {
  text-align: center;
  font-size: 40px;
  color: #c11f3b;
  margin-bottom: 20px;
}

.modal-body p {
  text-align: center;
  font-size: 20px;
}

.modal-body .detail_container_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.detail_container {
  padding: 0 10px;
  margin-bottom: 15px;
}

.modal-body .detail_container_content .detail_container .detail_label {
  font-size: 19px;
  font-weight: 500;
  color: #c11f3b;
  margin-bottom: 8px;
}
.modal-body .detail_container_content .detail_container .detail_content {
  font-size: 17px;
  font-weight: 400;
  color: #010101;
}

.last-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.last-modal-para {
  font-size: 19px;
  font-weight: 500;
  color: #c11f3b;
  margin-bottom: 8px;
  text-align: center;
}

.modal-footer {
  text-align: center;
  margin-top: 25px;
}

.modal-footer .btn,
.modal-footer .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
  cursor: pointer;
}

.modal-footer .btn-secondary {
  background: #c1c1c1;
}
.modal-footer .btn-secondary:hover,
.modal-footer .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-footer .btn-danger {
  /* background: #f15e5e; */
  background: #c11f3b;
}
.modal-footer .btn-danger:hover,
.modal-footer .btn-danger:focus {
  background: #ee3535;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item {
  border-bottom: 1px solid #c1203b1f;
  padding: 10px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.table_item {
  text-align: center;
}
.credited_points {
  color: green !important;
  font-weight: bold !important;
}
.debited_points {
  color: red !important;
  font-weight: bold !important;
}
.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row:last-child
  .custom_data_table_item {
  border-bottom: 0;
}

.custom_data_table_view_edit_btn_item_row {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  max-width: 70px;
  width: 100%;
  gap: 20px;
  margin: 0 auto;
  cursor: pointer;
}

.custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border: none;
  height: 30px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 7px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn:hover {
  background: #c11f3b;
  color: #ffffff;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button,
.custom_data_table_button {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
}
.unverified-properties-btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  margin: 0 auto;
  /* cursor: pointer; */
}

.sold_button {
  margin-left: 0.5rem;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button:hover,
.custom_data_table_button:hover {
  color: #ffffff;
  background: #c11f3b;
}

/* data table css end */

/* plan page css start */

.plan_list_column {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 24px;
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
}

.plan_list_column .plan_list_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 240px;
  max-height: 240px;
}

.plan_list_column .plan_list_img {
  position: relative;
  border-radius: 5px;
  width: 100%;
  min-height: 240px;
  max-height: 240px;
  overflow: hidden;
}

.plan_list_column .plan_list_img .plan_list_add_update_time {
  background: #0000006e;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px;
  box-shadow: 0px -2px 10px 1px #00000035;
  width: 100%;
  display: flex;
  align-items: center;
}

.plan_list_column .plan_list_img .plan_list_add_update_time p {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.plan_list_column .plan_list_img .plan_list_add_update_time p span {
  font-size: 12px;
  color: #fff;
  display: block;
  margin-top: 3px;
}

.plan_list_column
  .plan_list_img
  .plan_list_add_update_time
  .plan_list_update_time {
  margin-left: auto;
  text-align: right;
}

.plan_list_column .plan_list_body {
  padding: 18px 0 0;
  display: flex;
  flex-direction: column;
  min-height: 140px;
  height: auto;
}
.plan_list_column .plan_list_body .plan_list_title {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 10px;
}

.plan_list_column .plan_list_body .plan_list_name {
  font-weight: 500;
  display: block;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #c11f3b;
  text-decoration: none;
  transition: 0.3s;
}

.plan_list_column .plan_list_body .plan_list_bottom_btn {
  border-top: 2px solid #fbf1f3;
  /* margin-top: 14px; */
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: auto;
}

.plan_list_column .plan_list_body .plan_list_bottom_btn .plan_list_bottom {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c11f3b;
  text-decoration: none;
  max-width: 165px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.plan_list_column .plan_list_body .plan_list_bottom_btn .plan_list_bottom svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.plan_list_column
  .plan_list_body
  .plan_list_bottom_btn
  .plan_list_bottom:hover
  svg {
  background: #c11f3b;
  color: #ffffff;
}

.plan_slider_box {
  position: relative;
  max-height: 350px;
  min-height: 350px;
}

.plan_slider_box .plan_slider_box_text_content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #0000008f;
  padding: 15px;
  box-shadow: 0px -3px 11px 3px #00000059;
}

.plan_slider_box .plan_slider_box_text_content .plan_slider_heading {
  font-size: 24px;
  color: #fff;
}

.plan_slider_box
  .plan_slider_box_text_content
  .plan_slider_heading
  ~ .plan_slider_peragraph {
  margin-top: 4px;
}
.plan_slider_box .plan_slider_box_text_content .plan_slider_peragraph {
  font-size: 16px;
  color: #fff;
}

.plan_page_section .about_page_slider {
  padding-bottom: 30px;
}

.plan_slider_box .plan_slider_box_text_content .plan_slider_category {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 4px;
}

.plan_slider_pdf {
  width: 100%;
  height: 270px;
}

.plan_photo_view {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #aaaaaa;
  font-size: 25px;
  border: 1px solid #aaaaaa;
  padding: 2px 10px;
  border-radius: 4px;
}

.plan_photo_view:hover {
  opacity: 0.7;
}

/* plan page css end */

/* customer properties page css start */

.customer_properties_column {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 24px;
  display: flex;
  flex-direction: column;
}

.customer_properties_column .customer_properties_user_type_name {
  border-radius: 5px;
  padding: 10px;
  border-left: 5px solid #c11f3b;
}

.debited .customer_properties_user_type_name,
.customer_properties_user_type_name {
  background: #c11f3b1a;
}

.credited .customer_properties_user_type_name {
  background: #039c291a;
}
.customer_properties_column
  .customer_properties_user_type_name
  .customer_properties_user_type {
  font-size: 11px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
}

.customer_properties_column
  .customer_properties_user_type_name
  .customer_properties_user_name {
  font-size: 16px;
  /* color: #c11f3b; */
  font-weight: 600;
  display: block;
}

.credited .customer_properties_user_type_name {
  border-left: 5px solid green;
}

.debited .customer_properties_user_name,
.customer_properties_user_name {
  color: #c11f3b;
}

.credited .customer_properties_user_name {
  color: green;
}

.customer_properties_column .customer_properties_other_details {
  margin-top: 10px;
  border-bottom: 1px solid #c1203b1f;
  padding-bottom: 10px;
}

.customer_properties_column
  .customer_properties_other_details
  .customer_properties_other_details_label {
  font-size: 13px;
  color: #c11f3b;
  font-weight: 400;
  display: block;
  margin-bottom: 1px;
  font-weight: 500;
}

.customer_properties_column
  .customer_properties_other_details
  .customer_properties_other_details_text {
  font-size: 17px;
  font-weight: 700;
  margin-top: 10px;
  color: #000000b5;
  display: block;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}

.customer_properties_column
  .customer_properties_discription_details
  .customer_properties_other_details_text {
  height: 85px;
}

.customer_properties_column.CUSTOMER .customer_properties_user_type_name {
  background: #ffb8001a;
  border-color: #ffb800;
}

.customer_properties_column.CUSTOMER
  .customer_properties_user_type_name
  .customer_properties_user_type,
.customer_properties_column.CUSTOMER
  .customer_properties_user_type_name
  .customer_properties_user_name,
.customer_properties_column.CUSTOMER
  .customer_properties_other_details
  .customer_properties_other_details_label {
  color: #ffb800;
}

.customer_properties_column.CUSTOMER .customer_properties_other_details {
  border-color: #ffb8001f;
}

.customer_properties_column .project_list_card_bottom_btn_row {
  margin-top: auto;
}

.customer_properties_column .customer_properties_other_details:nth-child(4) {
  margin-bottom: 5px;
}

.property-next-btn {
  margin-top: 1rem;
}

.sg-user-already-created-number-text .already-created-number-message {
  display: flex;
  justify-content: center;
}

input[type="checkbox" i] {
  accent-color: #c11f3b;
}

.notice_column {
  display: flex;
  flex-direction: column;
}

.notice_create_edit_date_row {
  display: flex;
  align-items: center;
}

.notice_create_edit_date_row .notice_edit_date_text {
  margin-left: auto;
  text-align: right;
}

.notice_create_edit_date_row .notice_date_heading {
  font-size: 16px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
}

.notice_create_edit_date_row .notice_date_time_text {
  font-size: 11px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
}

.notice_subject_discription_text {
  margin-top: 20px;
  margin-bottom: 10px;
}

.notice_subject_discription_text .notice_subject_text {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #00000035;
}

.notice_subject_discription_text .notice_discription_text {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: #000000;
}

.notice_column .project_list_card_bottom_btn_row {
  margin-top: auto;
}
.lead_btn_container {
  display: flex;
  justify-content: center;
}
.notice_column
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn {
  justify-content: flex-start;
}

.plan_list_bottom_btn .only-btn-css {
  background: transparent;
  border: none;
  cursor: pointer;
}

.verify_btn {
  margin-top: 1rem;
}

.project_edit_feature_input ~ .project_edit_media_input_file {
  margin-top: 16px;
}

.project_edit_detail_divider {
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
  margin-bottom: 16px;
}

.project_edit_detail_divider .project_edit_detail_divider {
  border-bottom: 1px solid #00000038;
}

.project_edit_detail_divider:last-child,
.project_edit_detail_divider .project_edit_detail_divider:last-child {
  border-bottom: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.create_from_row .project_edit_detail_column_content {
  box-shadow: none;
  border-bottom: 2px dashed #00000047;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.create_from_row .project_edit_detail_column_content:last-child {
  border-bottom: 0;
}

.single_column_body .create_from_row,
.amenities_body .create_from_row {
  gap: 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.document_body .project_edit_feature_media_row {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.no_data_found {
  align-items: center;
}

.center_table_body {
  max-width: 1140px;
  margin: 0 auto;
}
.csr_data_component {
  margin-top: 1.5rem;
}
.ta_c {
  text-align: center !important;
}
.c-red {
  color: #c11f3b !important;
}
.no-results-found-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.lead_page_body .user_data_search_select_box_row .user_data_search {
  margin-right: 0;
}

.inquiry_complaint_detail_section {
  padding: 20px;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.inquiry_complaint_detail_section .inquiry_complaint_detail_content {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_video_img {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_video_img
  .default_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_video_img
  .inquiry_complaint_detail_status {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #f9e8eb;
  color: #c11f3b;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_video_img
  iframe {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 0 0;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body {
  padding: 20px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_create_updated_date {
  padding: 20px;
  margin: -20px -20px 20px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_create_updated_date
  h2 {
  color: #c11f3b;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_create_updated_date
  .inquiry_complaint_detail_updated_date {
  margin-left: auto;
  text-align: right;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_create_updated_date
  p {
  color: #c11f3b;
  font-size: 15px;
  font-weight: 600;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_heading {
  font-size: 19px;
  font-weight: 500;
  color: #c11f3b;
  margin-bottom: 5px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_name {
  font-size: 17px;
  font-weight: 400;
  color: #010101;
  text-transform: capitalize;
  padding-left: 16px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #00000035;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

/* customer properties page css end */

/* dashboard page css start */

.dashboard_body {
  padding: 20px;
}

.dashboard_top_column_row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 30px;
}

.dashboard_top_column_row .dashboard_top_column {
  box-shadow: 0 5px 20px #9299b808;
  border-radius: 10px;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_count_text {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  color: #272b41;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_peragraph_text {
  margin: 2px 0 24px;
  color: #868eae;
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  text-transform: capitalize;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text {
  color: #868eae;
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: #20c997;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span
  svg {
  margin-right: 2px;
}

.dashboard_top_column_row
  .dashboard_top_column.down_grad
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span {
  color: #ff4d4f;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_main_icon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c11f3b1a;
}

.dashboard_project-link {
  text-decoration: none;
}

.user_profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user_detail_modal_bg {
  background: #272d4e;
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: -100%;
  /* z-index: -9; */
  z-index: 999999;
  width: 100%;
  height: 100%;
  /* opacity: 0; */
  /* display: none; */
  opacity: 0.58;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.user_detail_modal_bg.user_detail_modal_show {
  /* opacity: 0.58; */
  /* z-index: 999999; */
  /* display: block; */
  left: 50%;
}

.user_detail_modal_main {
  background: linear-gradient(0deg, #fff, #fff);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: -100%;
  top: 50px;
  /* z-index: -9; */
  z-index: 999999;
  max-height: calc(100vh - 100px);
  width: 100%;
  max-width: 1116px;
  overflow-y: auto;
  border: 1px solid #8a98a8;
  padding: 68px;
  /* opacity: 0; */
  /* display: none; */
}

.modal-close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 34px;
  height: 34px;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #ffffff;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
}

.user_detail_modal_main.user_detail_modal_show {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.user_property {
  display: flex;
  justify-content: space-between;
}

.user_referred_btn {
  padding: 2px 13px;
}

.create_notice_heading {
  text-align: center;
  margin-bottom: 1rem;
}

.create_notice_buttons {
  display: flex;
  justify-content: center;
}
.create_notice_buttons > * {
  margin: 0 1rem;
}

.create_project_review {
  font-size: 12px;
  line-height: 15px;
  margin: 10px 0 8px 0;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #59667a;
  display: inline-block;
}

.rera_number {
  width: 100%;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 32px -5px 0;
}

.pagination .pagination__link--disabled {
  pointer-events: none;
}

.pagination li {
  padding: 0 5px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c11f3b;
  color: #c11f3b;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #c11f3b;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.user_lead_heading {
  font-size: 25px;
  color: #c11f3b;
  margin-bottom: 6px;
  text-decoration: underline;
}

.user_detail_lead {
  margin-bottom: 1.5rem;
}

.user_lead-project {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.user_lead-status {
  font-size: 13px;
}

.user_detail-property {
  display: flex;
  justify-content: space-between;
}

.no_data_found_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 10px;
  margin-top: 200px;
  margin-right: 100px;
  /* margin: 60px; */
  margin-left: 100px;
}

.user_detail-nodata {
  text-align: center;
  color: #c11f3b;
}
.top-margin {
  margin-top: 50px;
}
.left-margin {
  margin-left: 200px;
  width: 300px;
}

.user_reward_header {
  display: flex;
  justify-content: space-between;
}

.user-detail_navbar {
  position: relative;
}

.user_wallet_status {
  position: absolute;
  right: 25px;
  display: flex;
  align-items: center;
}

.user_wallet_heading {
  margin-right: 1rem;
}

.user_detail-walletbalance {
  font-size: 15px;
  padding: 3px 13px;
  font-weight: 600;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
}

.user_detail-balance {
  margin-left: 10px;
  background: white;
  color: #c11f3b;
  padding: 3px 5px;
  border-radius: 5px;
}

.tower_dropdown {
  z-index: 999;
}

.create_lead_referred {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.complaint_view_modalbtn {
  margin-top: 2rem;
}

.assign_view_modalbtn {
  margin-top: 5px;
}

.lead_status_rejected_confirmed {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.notice_header {
  justify-content: space-between;
}

.notice_user-type {
  font-size: 13px;
  font-weight: bold;
  color: #c11f3b;
}

.flex-end {
  justify-content: flex-end !important;
}

.btm_mar {
  margin-bottom: 10px;
}

/* #chart{
  width: 130px !important;
  height: 93px !important;
} */

/* dashboard page css end */

.msger-chat {
  overflow-y: auto;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #eff2f7;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  max-height: 420px;
  min-height: 420px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msger-chat .msg.left-msg .msg-bubble {
  padding: 12px 24px;
  background-color: #c11f3b1a;
  border-radius: 0 8px 8px 8px;
  overflow: hidden;
}
.msger-chat .msg-text {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
}
.msger-chat .msg-info-time {
  color: #c11f3b;
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
}
.msger-chat .msg.right-msg .msg-bubble {
  background-color: #eff2f7;
  text-align: right;
  border-radius: 8px 8px 0 8px;
  padding: 12px 24px;
}
.msger-chat .msg.right-msg .msg-text,
.msger-chat .msg.right-msg .msg-info-time {
  color: #000000;
  text-align: right;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
  position: relative;
  padding-right: 28px;
}
.right-msg .right-msg-more-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 22px;
  height: 22px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
}
.right-msg .right-msg-more-btn svg {
  width: 22px;
  height: 22px;
}
.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}
.msger-inputarea {
  display: flex;
  padding: 10px;
  border: 1px solid #eff2f7;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
}
.msger-input {
  flex: 1;
  padding: 7.5px 12px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}
.msger-send-btn {
  margin-left: 10px;
  background: #c11f3b;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 7px;
  border-radius: 5px;
}
.msger-send-btn svg {
  width: 22px;
  height: 22px;
}

.create_project_properties_input_box .comman_btn {
  margin-top: 16px;
}
.contact_page_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact_page_filter .user_data_search_select_box_row,
.contact_page_filter .user_data_search_select_box_row .user_data_search {
  margin: 0;
}

.assign_contacts_page .project_edit_main_content {
  padding: 16px 20px;
}

.input_css {
  margin-top: -11px;
}
.create_update_in_single_div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #00000035;
}

.create_update_in_single_div .inquiry_complaint_detail_text {
  border-bottom: 0 !important;
}
.assign_view_modal_heading {
  font-size: 24px;
  font-weight: 600;
  color: #c11f3b;
  margin-bottom: 0px;
}
.contact_property_main_wrapper {
  grid-column: span 2;
}
.contact-properties-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 10px;
}
.contact-properties-container > .create_from_input_content:first-child {
  width: 50%;
}
.contact_multi_select_wrapper > .create_from_input_content {
  width: 45%;
}
.contact-properties-container .create_from_input_content {
  padding: 0 5px;
  width: 25%;
}
.contact_property_main_wrapper .project_edit_feature_add_btn {
  margin-right: 10px;
}
.contact_property_main_wrapper .basic-multi-select {
  z-index: 9;
}
.contact_property_main_wrapper .css-1nmdiq5-menu {
  z-index: 11;
}
.contact_multi_select_wrapper {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
.contact-properties-container .project_edit_feature_add_remove_btn svg {
  margin: 0;
  width: 20px;
  height: 20px;
}
.contact-properties-container .project_edit_feature_add_btn {
  padding: 7.5px;
  margin: 0;
}
.contact-properties-container
  .project_edit_feature_add_remove_btn_row
  > div:first-child {
  margin-right: 5px;
}
.contact-properties-container
  .project_edit_feature_add_remove_btn_row
  > div:last-child {
  margin-right: 0;
}
.project_edit_feature_add_remove_btn_row {
  margin-top: auto;
  /* margin-left: auto; */
}
.contact_multi_select_wrapper .create_from_input_content {
  width: 45%;
}
.main_temp_class {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.property_tower_wrapper {
  width: 100%;
  display: flex;
  background-color: rgb(193 31 59 / 21%);
  /* padding: 10px; */
  border-radius: 5px;
  margin: 3px;
  border: 1px dashed #c11f3b;
}
.property_tower_select {
  width: 70%;
  /* margin-left: 20px; */
}
.contact-properties-container .create_from_checkbox_content {
  width: 100%;
  margin: 10px 0;
  padding: 0 5px;
}
.contact-properties-container .create_from_checkbox_content h3 {
  margin-bottom: 5px;
}
.contact-properties-container .create_from_checkbox_content label {
  display: contents;
  font-size: 14px;
  margin-left: 5px;
  flex: 1;
  cursor: pointer;
}
.create-contact-check-wrapper {
  display: flex;
  align-items: center;
}
.create-contact-check-container {
  display: flex;
  flex-wrap: wrap;
}
.create-contact-check-wrapper {
  display: flex;
  align-items: center;
  width: 33.3333%;
}

.create-contact-check-wrapper .contact_checkbox {
  cursor: pointer;
  margin-right: 5px;
}

.property_tower_label {
  width: 30%;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 10px;
}
.property_tower_select {
  padding: 10px;
}
.property_tower_label .label {
  width: 50% !important;
}

.privilege_extra_class {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.verify_user_modal {
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}
.verify_user_modal_content {
  padding: 68px;
  background: linear-gradient(0deg, #fff, #fff);
}

/* media query css start */
@media (max-width: 1700px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 130px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 162px;
  }
}

@media (max-width: 1600px) {
  .project_edit_main_content .col_lg_4 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1500px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 95px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 100px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 132px;
  }
}

@media (max-width: 1400px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 132px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 1300px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_4,
  .lead_list_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 1200px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 95px;
  }

  .document_body .project_edit_feature_media_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1100px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 991px) {
  .sidebar_container {
    max-width: 90px;
  }
  #sidebar_toggel:checked ~ .sidebar_container {
    max-width: 280px;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link span,
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link::before {
    display: none;
  }
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link
    span,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link::before {
    display: block;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link svg {
    margin: 0 auto;
  }
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link
    svg {
    margin-right: 16px;
    margin-left: 0;
  }
  .sidebar_container .sidebar_logo .small_sidebar_logo,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_logo
    .full_sidebar_logo {
    display: block;
  }
  .sidebar_container .sidebar_logo .full_sidebar_logo,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_logo
    .small_sidebar_logo {
    display: none;
  }
  .main_content {
    max-width: calc(100% - 90px);
  }
  #sidebar_toggel:checked ~ .main_content {
    max-width: calc(100% - 280px);
  }
  .top_header_section {
    width: calc(100% - 89px);
  }
  .project_edit_content .project_edit_navbar {
    max-width: calc(100% - 89px);
  }
  #sidebar_toggel:checked ~ .main_content .top_header_section {
    width: calc(100% - 280px);
  }
  #sidebar_toggel:checked
    ~ .main_content
    .project_edit_content
    .project_edit_navbar {
    max-width: calc(100% - 280px);
  }
  .project_edit_main_content .project_edit_feature_media_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 175px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 175px;
  }
}

@media (max-width: 767px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content .col_lg_4,
  .lead_list_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .top_header_section
    .top_navbar_nav
    .top_navbar_item
    .top_navbar_logout_btn
    span {
    display: none;
  }
  .top_header_section
    .top_navbar_nav
    .top_navbar_item
    .top_navbar_logout_btn
    svg {
    margin-right: 0;
  }
  .top_header_section .top_navbar_nav .top_navbar_item .top_navbar_logout_btn {
    padding: 6px 8px;
  }
  .footer_section {
    flex-direction: column;
    text-align: center;
    padding: 15px 10px;
  }
  .footer_section .footer_version_text {
    margin: 15px auto 0;
  }
  .verify_user_modal {
    padding: 0 15px;
  }
  .verify_user_modal_content {
    padding: 34px;
  }
  .verify_user_modal .modal-footer .btn {
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .customer_properties_column
    .customer_properties_user_type_name
    .customer_properties_user_type {
    font-size: 10px;
  }
  .customer_properties_column
    .customer_properties_user_type_name
    .customer_properties_user_name {
    font-size: 13px;
  }
  .customer_properties_column {
    padding: 15px 15px 10px;
  }
}

@media (max-width: 500px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .top_header_section {
    padding: 15px 10px;
    width: calc(100% - 70px);
  }
  .sidebar_container {
    max-width: 70px;
    padding: 15px 10px;
  }
  .main_content {
    max-width: calc(100% - 70px);
  }
  .project_list_card .project_list_card_img {
    min-height: 160px;
    max-height: 160px;
  }
  .sidebar_container .sidebar_nav {
    margin: 30px -10.5px -15px;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link {
    padding: 12px;
  }
  .about_page_section .about_page_slider .slick-slider,
  .about_page_section .about_page_slider .slick-slider .slick-slide,
  .about_page_section .about_page_slider .slick-slider .slick-slide img {
    max-height: 320px;
    min-height: 320px;
    max-width: 320px;
  }
  .plan_slider_box {
    max-height: 320px;
    min-height: 320px;
  }
}
