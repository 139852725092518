.create_lead-page,
.create_customerproperty-page,
.create_project-page,
.create_plan-page {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.error {
  color: red;
}

.form-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(243, 243, 244);
  padding: 0.5rem 0.75rem;
  margin: 0.5rem;
  border-radius: 0.4rem;
  color: rgb(103, 106, 108);
}

.form-div-radio {
  display: flex;
  flex-direction: column;
  background-color: rgb(243, 243, 244);
  padding: 0.35rem 0.5rem;
  margin: 0.5rem;
  border-radius: 0.4rem;
  color: rgb(103, 106, 108);
}

.input {
  padding: 0.5rem 0.75rem;
  border: none;
  margin: 0 0 0 1rem;
  border-radius: 0.35rem;
  width: 70%;
}
.input:focus {
  border: 1px solid grey;
}

label {
  width: 30%;
}

.input-radio {
  font-size: 1rem;
  margin-right: 0.75rem;
}

.lead-label {
  margin-bottom: 0.75rem;
}

.width-select {
  padding: 0;
  width: 25.5rem;
}

.create-btn {
  padding: 0.5rem 0.75rem;
  margin: 1rem 0 0 0.65rem;
  border: none;
  background-color: #aaa;
  color: white;
  border-radius: 0.4rem;
  font-size: 1rem;
  cursor: pointer;
}
.create-btn:hover {
  opacity: 0.8;
}

.flex-row {
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
}

.radio-input-flex {
  display: flex;
  align-items: center;
}

.doc-name {
  padding: 0.35rem 0.75rem;
  margin: 0 0.5rem;
  background-color: #fff;
}

.user-property {
  position: relative;
  display: flex;
  flex-direction: column;
}

.user-search {
  position: absolute;
  left: 0;
  top: 0.5rem;
}

.user-search-input {
  padding: 10px;
  border: none;
  border: 1px solid hsl(0, 0%, 80%);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: hsl(0, 0%, 20%);
}

.user-search-btn {
  padding: 10px;
  border: none;
  border: 1px solid hsl(0, 0%, 80%);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: hsl(0, 0%, 20%);
  cursor: pointer;
}
.user-search-btn:hover {
  opacity: 0.8;
}

.userType-select {
  width: 30%;
  position: absolute;
  right: 1rem;
  top: 0;
  margin-top: 0.5rem;
}

.create_customerproperty-page {
  margin-top: 5rem;
}

.flex {
  display: flex;
}
.flex ul {
  list-style-type: disclosure-closed;
}

.flex li.open {
  list-style-type: disclosure-open;
}

.flex ul li button {
  border: none;
}
